import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import StrumentoPageTemplate from '../components/template-strumento';

const StrumentoPage = ({ data }) => {
  const { strumento } = data;
  const { title, tagTitle, metaDescription, image } = strumento;
  return (
    <Layout className="home" data={data}>
      <Seo
        title={tagTitle || title}
        ogImage={image.childImageSharp.resize}
        ogType="article"
        description={metaDescription}
        data={data}
      />
      <StrumentoPageTemplate {...strumento} />
    </Layout>
  );
};

export default StrumentoPage;
export const query = graphql`
  query StrumentoQuery($id: String!) {
    ...HeaderFragment
    ...SeoFragment
    strumento: strumentoJson(id: { eq: $id }) {
      title
      tagTitle
      metaDescription
      text
      image {
        childImageSharp {
          gatsbyImageData(height: 400, layout: CONSTRAINED, placeholder: TRACED_SVG)
          resize(height: 400) {
            src
            width
            height
          }
        }
      }
    }
  }
`;
