import React from 'react';
import Img from './img-wrapper';
import Breadcrumb from './breadcrumb';

const StrumentoPageTemplate = ({ title, text, image }) => {
  const breadcrumb = [
    {
      link: '/chi-sono/il-mio-metodo-di-lavoro/',
      name: 'Il mio metodo di lavoro'
    },
    {
      name: title
    }
  ];
  return (
    <div className="section strumento mt-0">
      <div className="container-xl">
        <div className="row mb-3">
          <div className="col-12">
            <Breadcrumb breadcrumb={breadcrumb} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h1>{title}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Img image={image} alt="" className="strumento__img float-sm-left me-4 mb-3" />
            <div className="wrap-list-md-left" dangerouslySetInnerHTML={{ __html: text }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StrumentoPageTemplate;
